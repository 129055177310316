<template>
  <div class="step">
    <div class="section-year">{{ step.year }}</div>
    <section class="step-item">
      <div class="item">
        <span class="dot"></span>
        <h3 class="date-item">{{ $t("months[" + step.month + "]") }}</h3>
        <h4 class="title-item">{{ step.name }}</h4>
        <p class="description-item">
          {{ step.description }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// import moment from 'moment';

export default {
  name: "JourneysStep",
  props: {
    step : {
      default: () => {
        return {
          year: new Date().getFullYear(),
          month: new Date().getMonth(),
          name: "Name",
          description:  `Lorem ipsum dolor sit amet consectetur adipisicing elit.
                         Eius earum architecto dolor, vitae magnam voluptate
                         accusantium assumenda numquam error mollitia, officia
                          facere consequuntur reprehenderit cum voluptates,
                          ea tempore beatae unde.`
        }
      },
      type: Object
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .step{
    display: grid;
    grid-template-columns: 100px 1fr;
  }
  .section-year{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 15px;
    font-weight: 700;
    font-size: 18px;
  }

  .step-item{
    text-align: left;
  }
  .step-item .item{
    border-left: 5px solid #ccd5db;
    padding: 15px 0 20px 15px;
    position: relative;
  }
  .step-item .dot{
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    left: -10px;
    top: 15px;
    background: rgb(45, 161, 191) none repeat scroll 0% 0%;
  }
  .step-item .date-item {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .step-item .title-item{
    margin: 0;
    padding: 5px 0;
    font-size: 15px;
    font-weight: 500;
  }
  .step-item .description-item{
    font-weight: 100;
    margin: 0;
    white-space: pre-line;
  }
  .step:first-child .step-item .dot{
    top: 0px;
  }
  .step:first-child .section-year, .step:first-child .step-item .item{
    padding-top: 0px;
  }
  .step:last-child{
    margin-bottom: 20px;
  }
</style>