<template>
  <section >
    <v-row no-gutters>
      <v-img
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          src="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&crop=bottom&w=1951&h=1197&q=80"
      >
        <v-theme-provider dark>
          <v-container fill-height>
            <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
            >
              <v-col
                  class="white--text text-center"
                  cols="12"
                  tag="h1"
              >
                <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                    class="font-weight-light"
                >
                  {{ $t("firstTab.welcome") }}
                </span>

                <br>

                <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                    class="font-weight-black"
                >
                  {{ $t("firstTab.resume") }}
                </span>

              </v-col>

              <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-theme-provider>
      </v-img>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "TopPicture"
}
</script>

<style scoped>

</style>