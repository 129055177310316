<template>
  <div class="about">
    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">{{ $t("about.title") }}</h2>

        <v-responsive
            class="mx-auto mb-8"
            width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
        >
          {{ $t("about.description") }}
        </v-responsive>

        <v-avatar
            class="elevation-12 mb-12"
            size="128"
        >
          <v-img :src="require('../assets/profile.jpg')"></v-img>
        </v-avatar>

        <div></div>

        <v-btn
            color="grey"
            @click="$vuetify.goTo('#career')"
            outlined
            large
        >
            <span class="grey--text text--darken-1 font-weight-bold">
             {{ $t("about.button") }}
            </span>
        </v-btn>
      </v-container>

      <div class="py-12"></div>
    </section>
  </div>
</template>

<script>
// import $ from 'jquery';
// @ is an alias to /src

export default {
  name: 'About',
  components: {}
}
</script>