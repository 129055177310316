<template>
  <v-app id="inspire">
      <Header/>
      <router-view></router-view>
      <Footer/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  created() {
    this.$vuetify.theme.dark = this.$store.state.darkTheme;
    this.$i18n.locale = this.$store.state.locale;
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
