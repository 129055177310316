import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkTheme: false,
    locale: 'fr'
  },
  mutations: {    
    toggleDarkTheme: (state) => {
      state.darkTheme = !state.darkTheme
    },
    setLocale: (state, locale) => {
      state.locale = locale;
    },
    initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('store')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
				);
			}
		}
  },
  actions: {
  },
  modules: {
  }
})
