<template>
  <v-app-bar
      app
      height="100"
      class="over-altervista"
  >
      <v-avatar
          class="mr-3"
          color="grey lighten-5"
          size="70"
      >
        <v-img
            cover
            max-height="100%"
            :src="require('../assets/logo.jpg')"
        ></v-img>
      </v-avatar>

      <v-toolbar-title class="font-weight-black headline">
        GEOFFREY DUGAST
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-menu
          bottom
          left
          v-model="menu"
          :offset-x="offset"
          :close-on-content-click="closeOnContentClick"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>

              <v-list-item-content>
                <v-list-item-title>{{ $t("settings.title") }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t("settings.subtitle") }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-title>{{ $t("settings.parameters.darkMode") }}</v-list-item-title>
              <v-list-item-action>
                <v-switch
                  v-model="$vuetify.theme.dark"
                  color="purple"
                  @click="$store.commit('toggleDarkTheme')"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>{{ $t("settings.parameters.language") }}</v-list-item-title>
              <v-list-item-action>
                <v-select
                    v-model="select"
                    :items="items"
                    item-text="name"
                    item-value="locale"
                    @change="setLocale"
                >
                </v-select>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                text
                @click="menu = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  methods: {
    setLocale(item) {
      console.log(item);
      this.$i18n.locale = item;
      this.$store.commit('setLocale', item);
    }
  },
  computed: {
    select() {
      return {
          "locale": this.$store.state.locale
      }
    }
  },
  data() {
    return {
      offset: true,
      menu: false,
      closeOnContentClick: false,
      items: [
        { locale: 'fr', name: 'Français' },
        { locale: 'en', name: 'English' },
      ]
    }
  },
  created() {
  }
}
</script>
<style scoped>
.over-altervista{
  z-index: 10001 !important;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}
li {
  float: left;
}
li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
li a:hover {
  background-color: #111;
}
</style>
