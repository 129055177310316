<template>
  <div class="skills">
    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">{{ $t("skills.title") }}</h2>
      <ul class="deck programming-skills">
          <li>
              <div class="custom-cards">
                  <div class="card-title">JS</div>
              </div>
          </li>
          <li>
              <div class="custom-cards">
                <div class="card-title">CSS</div>
                <div class="test"></div>
              </div>
          </li>
      </ul>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Skills"
}
</script>

<style scoped>
 .deck {
   list-style-type: none;
 }
</style>