<template>
  <div id="career">
    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">{{ $t("schools.title") }}</h2>
      <section id="journey" class="pt-5">
        <JourneysStep
            v-for="step in steps"
            :key="step"
            :step="step"
        />

      </section>
    </v-container>
  </div>
</template>

<script>
import i18n from "@/i18n";
import JourneysStep from '@/components/JourneysStep'

export default {
  name: "Schools",
  components: {
    JourneysStep
  },
  computed: {
    steps: function() {
      return [
        {
          year: 2016,
          month: 6,
          name:  i18n.t("schools.steps[0].name"),
          description: i18n.t("schools.steps[0].description")
        },
        {
          year: 2018,
          month: 5,
          name:  i18n.t("schools.steps[1].name"),
          description: i18n.t("schools.steps[1].description")
        },
        {
          year: 2019,
          month: 8,
          name:  i18n.t("schools.steps[2].name"),
          description: i18n.t("schools.steps[2].description")
        },
        {
          year: 2020,
          month: 8,
          name:  i18n.t("schools.steps[3].name"),
          description: i18n.t("schools.steps[3].description")
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>